<template>
  <Main>
    <div class="auth-contents registration-form">
      <a-row :gutter="15">
        <a-col :xs="24" class="mt-20">
          <registration-heading />
        </a-col>
        <a-col class="mt-10" :xs="24">
          <sdCards headless>
            <basic-steps step="withIcon" size="default" :data="formSteps" :current="current" />
          </sdCards>
        </a-col>
        <a-col :xs="24">
          <company-details
            v-if="current === 0"
            :data="formState.companyDetails"
            :loading="buttonLoading"
            :showIorEorField="true"
            @submit:companyDetails="handleCompanyDetails"
            :editMode="editMode"
            :currentPassword="currentPassword"
          />
          <countries-coverage
            v-if="current === 1"
            :data="formState.countriesCoverage"
            :loading="buttonLoading"
            @previous="previous('countriesCoverage', $event)"
            @saveAndNext:countriesCoverage="submitCountriesCoverage($event, true)"
            @saveDraft:countriesCoverage="submitCountriesCoverage($event, false)"
            :editMode="editMode"
          />
          <price-list
            v-if="current === 2"
            :countries="formState.countriesCoverage"
            :data="formState.priceList"
            :loading="buttonLoading"
            :companyId="currentUser?._jv?.relationships?.company?._jv?.id"
            @next="current += 1"
            @previous="previous('priceList', $event)"
          />
           <terms-and-conditions
            v-show="current === 3"
            @previousStep="current -= 1"
            :loading="buttonLoading"
            @finishRegistration="finishRegistration"
          />
        </a-col>
      </a-row>
    </div>
  </Main>
</template>

<script>
import {
  computed,
  defineComponent, reactive, ref, watch
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import BasicSteps from '@/components/steps/Basic';
import CompanyDetails from '@/components/registration/CompanyDetails';
import CountriesCoverage from '@/components/registration/CountriesCoverage';
import PriceList from '@/components/registration/PriceList';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';
import TermsAndConditions from '@/components/registration/TermsAndConditions';
import RegistrationHeading from '@/components/registration/Heading';
import {useRoute} from 'vue-router';

export default defineComponent({
  components: {
    BasicSteps,
    Main,
    CompanyDetails,
    CountriesCoverage,
    PriceList,
    TermsAndConditions,
    RegistrationHeading
  },
  setup() {
    const {
      dispatch, state, commit
    } = useStore();
    const route = useRoute();

    if (!route.query.continue) {
      dispatch('resetSession');
      commit('setUserType', 'AgentUser');
    }

    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getCountries'),
      dispatch('getList', 'PRODUCT_CATEGORIES')
    ]).then(() => dispatch('toggleLoading', false));

    const buttonLoading = ref(false);
    const current = ref(0);

    const formState = reactive({
      companyDetails: {}, countriesCoverage: [], priceList: {}
    });
    const saveState = (key, data) => {
      if (Array.isArray(data)) {
        formState[key] = data;
      } else {
        Object.assign(formState[key], {...formState[key], ...data});
      }
    };

    const handleCompanyDetails = async (data) => {
      buttonLoading.value = true;
      const formData = new FormData();
      formatCompanyDetails(formData);
      const status = editMode.value ? await dispatch('validateCompanyUpdate', formData) : await dispatch('validateCompany', formData);
      buttonLoading.value = false;
      if (status === 204) {
        saveState('companyDetails', data);
        current.value += 1;
      }
    };

    const previous = (stepKey, data) => {
      saveState(stepKey, data);
      current.value -= 1;
    };

    const submitCountriesCoverage = async (data, proceed) => {
      await saveDraft('countriesCoverage', data, true);
      if (!proceed) return;

      current.value += 1;
    };

    const saveDraft = async (key, currentState, skipToaster) => {
      saveState(key, currentState);

      const formData = new FormData();
      formatCompanyDetails(formData);
      formData.append(_.snakeCase('skipUpdateStatus'), true);
      formData.append(`${_.snakeCase('agentAttributes')}[status]`, 'draft');

      buttonLoading.value = true;
      const requestData = {
        data: formData, saveDraft: true, skipToaster
      };
      const status = editMode.value ? await dispatch('updateCompanyRegistration', requestData) : await dispatch('registerCompany', requestData);
      buttonLoading.value = false;
      if (status !== 200) return;

      editMode.value = true;
      if (formState.companyDetails.password) currentPassword.value = formState.companyDetails.password;
    };

    const formatCompanyDetails = (formData) => {
      _.forEach(formState.companyDetails, (value, key) => {
        if (_.includes(['email', 'firstName', 'lastName', 'password', 'passwordConfirmation'], _.camelCase(key))) {
          formData.append(_.snakeCase(key), value);
        } else if ((key === 'tradeLicense' || key === 'businessRegistrationCertificate')) {
          if (value[0] instanceof File) formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}]`, value[0]);
        } else if (key === 'serviceTypes' || key === 'productCategoryIds') {
          _.forEach(value, (option) => formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}][]`, option));
        } else {
          formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase(key)}]`, value);
        }
        if (currentPassword.value) formData.append(_.snakeCase('currentPassword'), currentPassword.value);
      });

      _.forEach(formState.countriesCoverage, (value) => {
        formData.append(`${_.snakeCase('agentAttributes')}[${_.snakeCase('countriesCoverage')}][]`, value);
      });
    };

    const {t} = useI18n();
    const formSteps = [
      {
        id: 1,
        title: t('views.registration.companyDetails'),
        icon: 'home'
      },
      {
        id: 2,
        title: t('views.registration.countriesCoverage'),
        icon: 'map'
      },

      {
        id: 3,
        title: t('views.registration.priceList'),
        icon: 'dollar-sign'
      },
      {
        id: 4,
        title: t('views.registration.termsAndConditions'),
        icon: 'clipboard'
      }
    ];

    const currentUser = computed(() => state.session.currentUser);
    watch(currentUser, (value) => {
      if (value?._jv && value._jv.relationships.company.status !== 'approved') {
        const companyDetails = {};
        _.forEach(['email', 'firstName', 'lastName'], (key) => companyDetails[key] = value[key]);
        _.forEach(['serviceTypes', 'city', 'country', 'street', 'name',
          'phoneCode', 'registrationNumber', 'contactPersonName', 'contactPersonPosition'], (key) => {
          companyDetails[key] = value._jv.relationships.company[key] || undefined;
        });
        companyDetails['productCategoryIds'] = _.map(value._jv.relationships.company.productCategories, ({id}) => id.toString());
        companyDetails['phone'] = parseInt(value._jv.relationships.company['phone']) || undefined;
        _.forEach(['tradeLicense', 'businessRegistrationCertificate'], (key) => {
          companyDetails[key] = [{
            uid: value._jv.relationships.company[key]?.id,
            name: value._jv.relationships.company[key]?.filename,
            status: 'done',
            url: `${process.env.VUE_APP_GCE_BASE_URL}${value._jv.relationships.company[key]?.url}`
          }];
        });
        saveState('companyDetails', companyDetails);
        saveState('countriesCoverage', value._jv.relationships.company.countriesCoverage);
      }
    }, {deep: true, immediate: true});


    const editMode = ref(!_.isEmpty(currentUser.value));
    const currentPassword = ref('');

    const finishRegistration = async () => {
      buttonLoading.value = true;
      const response = await dispatch('updateCompanyRegistration', {
        data: {[_.snakeCase('agentAttributes')]: {[_.snakeCase('termsAndConditions')]: true}},
        skipLogin: true
      });

      if (response.countries) {
        const countryIndex = _.indexOf(formState.countriesCoverage, response.countries[0]);
        if (countryIndex < 0) return;

        formState.countriesCoverage.splice(countryIndex, 1);
        formState.countriesCoverage.unshift(response.countries[0]);
        current.value = 2;
      }
      buttonLoading.value = false;
    };

    return {
      buttonLoading,
      current,
      formState,
      saveDraft,
      saveState,
      handleCompanyDetails,
      previous,
      formSteps,
      editMode,
      currentPassword,
      currentUser,
      submitCountriesCoverage,
      finishRegistration
    };
  }
});
</script>
